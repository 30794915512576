<template>
  <div class="login-view">
    <preloader v-if="userLoading" class="preloader"/>
    <div v-else-if="showModal && !isMobile" class="login">
      <img class="login__logo" src="../assets/logo_dark.png" />
        <form class="login__form">
            <div class="form-group" v-if="isSignUp">
              <input type="name" autocomplete="false" v-model="name" placeholder="Имя" />
            </div>
            <div class="form-group">
              <input type="email" autocomplete="false" v-model="email" placeholder="Почта" />
            </div>
            <div class="form-group">
              <input type="password" autocomplete="false" v-model="password" placeholder="Пароль" />
            </div>
          <p v-if="message !== null" class="red">{{ message }}</p>
          <button class="btn" v-if="isSignUp" @click="register">
            <span class="btn__text">ЗАРЕГИСТРИРОВАТЬСЯ</span>
          </button>
          <button class="btn" v-else @click="logIn">
            <span class="btn__text">ВОЙТИ</span>
          </button>
          <span class="text-s gray sign" v-if="!isSignUp" @click="isSignUp = true">Нет аккаунта</span>
          <span class="text-s gray sign" v-else @click="isSignUp = false">Есть аккаунт</span>
        </form>
    </div>
    <div v-else-if="showModal && isMobile" class="mobile-login">
      <img class="login__logo" src="../assets/logo_dark.png" />
        <form class="mobile-login__form">
            <div class="form-group" v-if="isSignUp">
              <input type="name" autocomplete="false" v-model="name" placeholder="Имя" />
            </div>
            <div class="form-group">
              <input type="email" autocomplete="false" v-model="email" placeholder="Почта" />
            </div>
            <div class="form-group">
              <input type="password" autocomplete="false" v-model="password" placeholder="Пароль" />
            </div>
          <p v-if="message !== null" class="red">{{ message }}</p>
          <button class="btn" v-if="isSignUp" @click="register">
            <span class="btn__text">ЗАРЕГИСТРИРОВАТЬСЯ</span>
          </button>
          <button class="btn" v-else @click="logIn">
            <span class="btn__text">ВОЙТИ</span>
          </button>
          <span class="text-s gray sign" v-if="!isSignUp" @click="isSignUp = true">Нет аккаунта</span>
          <span class="text-s gray sign" v-else @click="isSignUp = false">Есть аккаунт</span>
        </form>
    </div>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader'
import { mapGetters } from "vuex";
export default {
  components: {
    Preloader,
  },
  data() {
    return {
      email: "",
      password: "",
      name: '',
      userLoading: false,
      showModal: true,
      isSignUp: false,
    };
  },
  methods: {
    logIn() {
      this.userLoading = true;
      if (!this.email.length || !this.password.length) {
        this.loading = false;
        return;
      }
      this.$store.dispatch("login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          if (this.message === null) {
            this.showModal = false
            this.$router.push({ name: 'main' })
          }
          this.userLoading = false;
        })
        .catch(() => {
          this.userLoading = false;
        });
    },
    register() {
        this.userLoading = true;
        if (!this.name.length || !this.email.length || !this.password.length) {
          this.loading = false;
          return;
        }
        this.$store.dispatch("register", {
            name: this.name,
            email: this.email,
            password: this.password
          })
          .then(() => {
            if (this.message === null) {
              this.showModal = false
              this.$router.push({ name: 'main' })
            }
            this.userLoading = false;
          })
          .catch(() => {
            this.userLoading = false;
          });
    },
  },
  computed: mapGetters(["message"]),
};
</script>

<style scoped>
.login-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.preloader {
  margin: auto;
}
.sign {
  cursor: pointer;
}
.mobile-login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-login__form {
  width: 80%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login {
  margin: auto;
  height: 550px;
  width: 450px;
  padding: 0px;
  box-shadow: 0px 7px 25px rgba(40, 110, 156, 0.2), 0 7px 25px rgba(40, 110, 156, 0.20);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login__logo {
  margin: 60px auto 20px auto;
  width: 140px;
  height: 60px;
}
.login__form {
  margin: auto;
  width: 80%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}
.form-group input {
    border: 0;
    border-radius: 4px 0 0 4px;
    height: 40px;
    padding: 0;
    border: 1px solid rgb(220, 220, 220);
    font: 13px proxima-regular;
    line-height: 40px;
    text-indent: 15px;
    width: 100%;
}
.form-group input:-webkit-autofill,
.form-group input:-webkit-autofill:hover, 
.form-group input:-webkit-autofill:focus, 
.form-group input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.form-group input:hover {
    border: 1px solid rgb(200, 200, 200);
}
.form-group input:focus {
    border: 1px solid rgb(180, 180, 180);
}
.btn {
    margin: 20px auto;
    border: 0;
    height: 40px;
    padding: 0 30px;
    border-radius: 60px;
    background: rgba(255, 219, 77, 1);
    cursor: pointer;

}
.btn:hover {
    background: rgba(255, 226, 111, 1);
}
.btn__text {
    font: 13px proxima-bold;
    line-height: 42px;
    letter-spacing: 0.1em;
}

.red {
  color: red;
  font: 13px proxima-regular;
}
</style>
